import React, { useEffect, useState } from "react";
import contentfulClient from "../clients/contentfulClient";
import ContentCard from "./ContentCard";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Sentry from "@sentry/react";

const StartOverButton = () => {
  return (
    <div className="actions">
      <button onClick={() => window.location.reload(false)} className="btn alt">
        Start Over
      </button>
    </div>
  );
};

const IntakeResultList = ({ results, contentfulEntries }) => {
  return (
    <>
      {results.map((result, id) => {
        const contentfulEntry = contentfulEntries?.items?.find(
          (entry) => entry.sys.id === result.cms_entry_id
        );
        return (
          <ContentCard
            key={id}
            entry={contentfulEntry}
            contentObject={result}
          />
        );
      })}
    </>
  );
};

const usePaymentFromRecommendation = process.env.REACT_APP_SET_PAYMENT_FROM_RECOMMENDATION === "true";
const useScheduleFromRecommendation = process.env.REACT_APP_SET_SCHEDULE_FROM_RECOMMENDATION === "true";

export default function IntakeResults({
  submissionId,
  setResultsLoaded,
  setDisqualified,
  setSubscriptionPlanId,
  setSchedulingLink,
}) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const [disqualifications, setDisqualifications] = useState(null);
  const [contentfulEntries, setContentfulEntries] = useState([]);

  useEffect(() => {
    // load suggestions
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ workflow: { submission_id: submissionId } }),
    };
    Sentry.addBreadcrumb({
      message: "Fetching suggestions for submission.",
      data: {
        submissionId: submissionId,
      },
    });
    fetch(
      `${process.env.REACT_APP_API_PATH}/workflows/run`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (results) => {
          if (results.errors) {
            Sentry.captureMessage(
              results.errors[0].title ||
                "Unable to fetch the suggestions for submission",
              {
                level: "error",
              }
            );
            setError(
              results.errors[0].title ||
                `Unable to fetch the suggestions for submission ${submissionId}`
            );
          } else {
            setDisqualifications(results.care_suggestions.filter((result) => result.weight < 0));
            setSuggestions(results.care_suggestions.filter((result) => result.weight > 0));

            const cmsEntryIds = results.care_suggestions.map(
              (suggestion) => suggestion.cms_entry_id
            );
            Sentry.addBreadcrumb({
              message: "Fetching content from Contentful.",
              data: {
                cmsEntryIds: cmsEntryIds.join(","),
              },
            });
            contentfulClient
              .getEntries({
                "sys.id[in]": cmsEntryIds.join(","),
              })
              .then((entries) => {
                setContentfulEntries(entries);

                if (entries?.items && usePaymentFromRecommendation) {
                  let stripePriceId = entries.items.find(
                    (entry) => entry?.fields?.stripePriceId
                  )?.fields?.stripePriceId ?? undefined;

                  if (stripePriceId === undefined) {
                    stripePriceId = entries.items.find(
                      (entry) => entry?.fields?.metadata?.stripePriceId
                    )?.fields?.metadata?.stripePriceId ?? undefined;
                  }
  
                  let subscriptionPlanId = entries.items.find(
                    (entry) => entry?.fields?.subscriptionPlanId
                  )?.fields?.subscriptionPlanId ?? undefined;

                  if (subscriptionPlanId === undefined) {
                    subscriptionPlanId = entries.items.find(
                      (entry) => entry?.fields?.metadata?.subscriptionPlanId
                    )?.fields?.metadata?.subscriptionPlanId ?? undefined;
                  }
                
                  setSubscriptionPlanId(stripePriceId ?? subscriptionPlanId);
                }

                if (entries?.items && useScheduleFromRecommendation) {
                  const schedulingLink = entries.items.find(
                    (entry) => entry?.fields?.metadata?.schedulingLink
                  )?.fields?.metadata?.schedulingLink ?? undefined;

                  setSchedulingLink(schedulingLink)
                }

                setIsLoaded(true);
                setResultsLoaded(true);
              })
              .catch((err) => {
                Sentry.captureException(err);
                console.log(err);
              });
          }
        },
        (error) => {
          Sentry.captureException(error);
          setError(
            error.message ||
              `Unable to fetch the suggestions for submission ${submissionId}`
          );
          setIsLoaded(true);
        }
      )
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  }, [submissionId, setResultsLoaded, setSubscriptionPlanId, setSchedulingLink]);

  useEffect(() => {
    if (disqualifications && disqualifications.length > 0) {
      setDisqualified(true);
    }
  }, [disqualifications, setDisqualified]);

  let content;

  if (error) {
    content = <div>Error: {error}</div>;
  } else if (!isLoaded) {
    content = <LoadingButton fullWidth loading color="primary" />;
  } else {
    content = (
      <>
        {disqualifications.length === 0 && suggestions.length > 0 ? (
          <h1>Recommendations</h1>
        ) : null}
        {disqualifications.length > 0 || suggestions.length > 0 ? (
          <IntakeResultList
            results={
              disqualifications.length === 0 ? suggestions : disqualifications
            }
            contentfulEntries={contentfulEntries}
          />
        ) : (
          <div style={{ textAlign: "center" }}>
            Thanks for completing the survey!
          </div>
        )}
        {process.env.REACT_APP_USAGE_MODE === "demo" && <StartOverButton />}
      </>
    );
  }

  return <div className="recommendations">{content}</div>;
}
